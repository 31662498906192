import React from 'react'
import PropTypes from 'prop-types'
import stylePropType from 'react-style-proptype'

const iconPaths = {
  arrowDown: 'M33.17 17.17L24 26.34l-9.17-9.17L12 20l12 12 12-12z',
  close: 'M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24z',
  facebook: 'M45.35 0H2.65A2.65 2.65 0 0 0 0 2.65v42.7A2.65 2.65 0 0 0 2.65 48h23V29.44h-6.24v-7.27h6.25v-5.35c0-6.2 3.79-9.57 9.31-9.57a53 53 0 0 1 5.58.28v6.48h-3.8c-3.02 0-3.6 1.43-3.6 3.53v4.62h7.2l-.94 7.27h-6.3V48h12.24A2.65 2.65 0 0 0 48 45.35V2.65A2.65 2.65 0 0 0 45.35 0z',
  linkedIn: 'M44.45 0H3.55A3.5 3.5 0 0 0 0 3.46v41.08A3.5 3.5 0 0 0 3.54 48h40.9A3.51 3.51 0 0 0 48 44.54V3.46C48 1.55 46.4 0 44.45 0zM14.24 40.9H7.1V18h7.13v22.9zm-3.56-26.03a4.13 4.13 0 1 1-.01-8.26 4.13 4.13 0 0 1 0 8.25zM40.9 40.9h-7.12V29.76c0-2.65-.04-6.07-3.7-6.07-3.7 0-4.26 2.9-4.26 5.88V40.9H18.7V18h6.83v3.13h.1c.95-1.8 3.27-3.7 6.73-3.7 7.21 0 8.54 4.74 8.54 10.91V40.9z',
  menu: 'M6 36h36v-4H6v4zm0-10h36v-4H6v4zm0-14v4h36v-4H6z',
  search: 'M29.06 17.81a.94.94 0 1 0 0 1.88.94.94 0 0 0 0-1.88zM46.9 41.6L34.39 29.08A18.77 18.77 0 0 0 18.75 0C8.41.01 0 8.42 0 18.76a18.77 18.77 0 0 0 29.09 15.64l3.45 3.46 9.06 9.05a3.73 3.73 0 0 0 5.3 0 3.72 3.72 0 0 0 0-5.3zm-18.25-9.19A16.9 16.9 0 0 1 1.88 18.75 16.9 16.9 0 0 1 18.75 1.87a16.9 16.9 0 0 1 13.66 26.77 17.04 17.04 0 0 1-3.76 3.77zm1.96.85c.97-.8 1.86-1.68 2.65-2.65l2.66 2.66a22.8 22.8 0 0 1-2.65 2.65l-2.66-2.66zm14.97 12.32a1.87 1.87 0 0 1-2.66 0l-8.32-8.33c.95-.81 1.84-1.7 2.65-2.65l8.33 8.32a1.86 1.86 0 0 1 0 2.66zM18.75 3.75c-8.27 0-15 6.73-15 15s6.73 15 15 15 15-6.73 15-15-6.73-15-15-15zm0 28.13A13.14 13.14 0 0 1 5.62 18.74c0-7.24 5.9-13.13 13.13-13.13 7.24 0 13.13 5.9 13.13 13.13 0 7.24-5.9 13.13-13.13 13.13zm10.5-17.15a11.26 11.26 0 0 0-10.5-7.23.94.94 0 0 0 0 1.88 9.44 9.44 0 0 1 8.76 6.02.94.94 0 1 0 1.75-.67z',
  twitter: 'M15.1 43.5c18.1 0 28.02-15 28.02-28.02 0-.43 0-.85-.03-1.27A20.04 20.04 0 0 0 48 9.1c-1.8.8-3.7 1.32-5.66 1.55a9.88 9.88 0 0 0 4.33-5.45 19.78 19.78 0 0 1-6.25 2.4 9.86 9.86 0 0 0-16.78 8.98 27.96 27.96 0 0 1-20.3-10.3 9.86 9.86 0 0 0 3.05 13.15 9.78 9.78 0 0 1-4.47-1.23v.13c0 4.68 3.3 8.72 7.9 9.65-1.45.4-2.97.45-4.45.17a9.86 9.86 0 0 0 9.2 6.84A19.76 19.76 0 0 1 0 39.08c4.5 2.89 9.74 4.42 15.1 4.41',
}

export const iconNames = Object.keys(iconPaths)

const Icon = ({
  className,
  color,
  name,
  size,
  style,
}) => (
  <svg
    className={`noevents ${className}`}
    height={size}
    preserveAspectRatio="xMinYMin"
    style={{ shapeRendering: 'geometricPrecision', ...style }}
    viewBox="0 0 48 48"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={iconPaths[name]}
      fill={color}
      fillRule="evenodd"
    />
  </svg>
)

Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.oneOf(iconNames).isRequired,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  style: stylePropType,
}
Icon.defaultProps = {
  className: '',
  color: 'currentColor',
  size: 48,
  style: {},
}

export default Icon
