export const translateOptions = [
  { value: 'english', label: 'English' },
  { value: 'spanish', label: 'Español' },
]
export const languages = {
  english: {
    navigationLinks: [{
      label: "Home",
      ref: "homeRef",
    }, {
      label: "About",
      ref: "aboutRef",
    }, {
      label: "Services",
      ref: "servicesRef",
    }, {
      label: "Contact",
      ref: "contactRef",
    }],
    splashText: "The journey to unlock your potential starts with a coaching conversation.",
    splashButton: "Learn More",
    aboutTitle: "About Dr. Rosa",
    aboutText: [
      "Dr. Rosa Grunhaus Belzer brings over 20 years of executive coaching, talent management, and leadership development to her work. Dr. Rosa began her career as a Clinical Psychologist and transitioned to ",
      " in 1999. She is fluent in Spanish, Hebrew, and English and has coached hundreds of leaders globally from a variety of industries at all leader levels. Between 2012 and 2020, Dr. Rosa has been the Coaching Talent Leader for the Center for Creative Leadership at the San Diego campus, managing a team of over 140 Adjunct Coaches in Western North America and Latin America.",
      "Dr. Rosa was born and raised in Venezuela and studied in Israel at Tel Aviv University, where she earned degrees in Psychology and Philosophy. Her Ph.D is from the California School of Professional Psychology and she is licensed a Psycholgist in San Diego. Dr. Rosa is a Professional Certified Coach from the International Coaching Federation. She's certified in mentor coaching, personality assessments, and 360° assessment tools. Dr. Rosa is passionate about public service and has run a development program to ",
      ". Over 180 graduates surveyed report significant increase in self-confidence and career advancement.",
    ],
    aboutLinkText: [
      "Executive Coaching",
      "inspire Latinas to become leaders",
    ],
    servicesTitle: "Services",
    serviceHeader1: "Executive Coaching",
    serviceText1: [
      "Develop into a better version of yourself and take the next steps in your career through personalized, 1-on-1 executive coaching for leaders.",
      "Gain insights and increased self-awareness leading to mindset and behavioral shifts.",
      "Embark on a coaching journey in partnership with Dr. Rosa that integrates leadership competencies with authenticity, courage, and candor.",
    ],
    serviceHeader2: "Leadership Development",
    serviceText2: [
      "Adopt a leadership mindset that shifts your problem-solving approach from telling to asking.",
      "Learn new ways to provide feedback to reduce reactivity and promote change.",
      "Through an individualized coaching style assessment with Dr. Rosa, hone your skills to empower your team and lead them to success.",
    ],
    serviceHeader3: "Mentor Coaching",
    serviceText3: [
      "Through conversation and coaching session reviews, increase your awareness of blindspots and emotional triggers so you can stay present and in the space of curiosity.",
      "Sharpen your tool box and learn how to always bring your “A” game with “coach’s coach” Dr. Rosa.",
    ],
    servicesButton: "Get Started",
    diversityTitle: "Diversity and Inclusion",
    diversityPhotoAltText: "Dr. Rosa teaching a course in front of a classroom",
    diversityText: [
      "Despite research that demonstrates that businesses with equity, diversity, and inclusion strategies outperform those without, there is still a long road to go.",
      "Dr. Rosa works at the individual leadership level with women, women of color, and leaders from diverse backgrounds to focus on what they can influence to move forward in their careers.",
      "Dr. Rosa helps leaders strengthen their voice despite organizational roadblocks and become aware of their own self-limiting beliefs, while achieving greater resilience and work-life integration. She encourages leaders to show up confident and act with purpose and intention.",
    ],
    testimonialsTitle: "Testimonials",
    testimonialsText: [{
      text: "Dr. Rosa is changing the world one Latina at a time! She is 5 feet of coaching dynamite who is teaching women how to advance their careers and assume leadership positions by recognizing their authentic self.",
      name: "Adela",
    }, {
      text: "Dr. Rosa is a gifted mentor coach who helped me expand my repertoire of coaching moves. She wears many hats, as an experienced executive coach and skillful mentor coach, to name but a few; and she brought all of these perspectives to our sessions.",
      name: "Robert",
    }, {
      text: "Dr. Rosa quickly established credibility through her warmth and depth of understanding of gender, ethnic, and political challenges faced in the work place by women. Her empathy and key coaching messages during some turbulent times made a positive difference.",
      name: "Darlene",
    }, {
      text: "Dr. Rosa's diverse background combined with her personal touch and business acumen made her the perfect personal and business coach for me. I would hire her again in a minute!",
      name: "Warren",
    }, {
      text: "Dr. Rosa is a remarkable person, coach, and leader. She brings structure while maintaining flexibility, warmth while remaining cool, fun while remaining focused, and drive while honoring the joy of the present. Dr. Rosa is a catalyst for growth. A true difference maker!",
      name: "Sheryl",
    }, {
      text: "It takes more than a good listener. It takes constructive actionable advice. Dr. Rosa delivers on both fronts.",
      name: "Jerry",
    }],
    contactText: "Contact Dr. Rosa to start your coaching journey today.",
    contactFormFields: [{
      label: "Name",
      name: "name",
      type: "text",
    }, {
      label: "Email",
      name: "email",
      type: "text",
    }, {
      label: "Message",
      name: "message",
      type: "textarea",
    }],
    contactFormButton: [
      "Send",
      "Sending...",
    ],
    formMessages: [
      "All fields are required.",
      "Please specify a valid email address.",
      "Thanks! We'll be in touch soon.",
    ],
  },
  spanish: {
    navigationLinks: [{
      label: "Inicio",
      ref: "homeRef",
    }, {
      label: "Acerca de",
      ref: "aboutRef",
    }, {
      label: "Servicios",
      ref: "servicesRef",
    }, {
      label: "Contacto",
      ref: "contactRef",
    }],
    splashText: "Libera tu potencial a través del coaching.",
    splashButton: "Más información",
    aboutTitle: "Acerca de la Dra. Rosa",
    aboutText: [
      "La Dra. Rosa Grunhaus Belzer aporta a su trabajo más de 20 años de coaching ejecutivo, gestión de talento y desarrollo del liderazgo. Inició su carrera como psicóloga clínica para después adentrarse en ",
      " en 1999. Habla con fluidez el español, el hebreo y el inglés, y ha asesorado a cientos de líderes de todo el mundo en una amplia gama de sectores y en todos los niveles de liderazgo. Desde 2012 hasta 2020, la Dra. Rosa ha sido Coaching Talent Leader para el Center for Creative Leadership en su campus de San Diego, California, con un equipo a su cargo de más de 140 coaches adjuntos para el Oeste de Estados Unidos y América Latina.",
      "La Dra. Rosa nació y se crio en Venezuela y estudió en Israel, en la Universidad de Tel Aviv, donde se tituló en Psicología y Filosofía. Se doctoró por la California School of Professional Psychology y está colegiada como psicóloga en San Diego. La Dra. Rosa es una coach profesional certificada por la International Coaching Federation. Cuenta también con certificación en mentor coaching, evaluación de la personalidad y técnicas de evaluación 360º. Es una apasionada del servicio público y ha dirigido un programa de desarrollo para ",
      ". Los resultados de la encuesta realizada a las 180 latinas graduadas indicaron una mejora notable tanto en su autoconfianza como en el desarrollo de sus trayectorias profesionales.",
    ],
    aboutLinkText: [
      "el coaching ejecutivo",
      "inspirar a mujeres latinas a avanzar en sus carreras",
    ],
    servicesTitle: "Servicios",
    serviceHeader1: "Coaching ejecutivo",
    serviceText1: [
      "Aprende a dar lo mejor de ti mismo: el coaching personalizado centrado en el liderazgo te ayudará a avanzar en tu carrera.",
      "Alcanza una visión más clara y una mayor autoconciencia que te permitirá efectuar cambios en tu modelos mentales y tu comportamiento.",
      "De la mano de la Dra. Rosa, emprende un camino de coaching que integre competencias de liderazgo con autenticidad ,valor y honestidad.",
    ],
    serviceHeader2: "Desarrollo del liderazgo",
    serviceText2: [
      "A la hora de liderar la resolución de problemas, adopta una nueva actitud: en lugar de ofrecer soluciones, haz preguntas.",
      "Aprende nuevas maneras de dar feedback para reducir la reactividad y fomentar el cambio.",
      "Mediante una evaluación individualizada del estilo de coaching con la Dra. Rosa, afina tus habilidades para empoderar a tu equipo y encaminarlo hacia el éxito.",
    ],
    serviceHeader3: "Mentor coaching",
    serviceText3: [
      "Las conversaciones y las revisiones de las sesiones de coaching te permiten tomar conciencia de tus puntos ciegos y de tus disparadores emocionales para mantenerte presente y abierto a la curiosidad.",
      "Mejora tu repertorio de herramientas y aprende a dar siempre lo mejor de ti con la coach de los coaches: la Dra. Rosa.",
    ],
    servicesButton: "Para empezar",
    diversityTitle: "Diversidad e inclusión",
    diversityPhotoAltText: "La Dra. Rosa impartiendo un curso en una clase",
    diversityText: [
      "A pesar de que los estudios demuestren que las empresas que disponen de estrategias de igualdad, diversidad e inclusión obtienen mejores resultados que las demás, todavía queda mucho camino por recorrer.",
      "La Dra. Rosa trabaja el nivel del liderazgo individual con mujeres en general, mujeres de color y líderes de orígenes diversos para centrarse en los aspectos en los que pueden influir para avanzar en sus trayectorias profesionales.",
      "La Dra. Rosa ayuda a los líderes a reforzar su propia voz a pesar de los obstáculos organizacionales y a cobrar conciencia de sus propias creencias autolimitantes, para que puedan adquirir una mayor resiliencia e integración entre la vida laboral y personal. Anima a los líderes a potenciar la confianza en sí mismos y a actuar con determinación e intención.",
    ],
    testimonialsTitle: "Testimonios",
    testimonialsText: [{
      text: "¡La Dra. Rosa está cambiando el mundo a través de las latinas! Con su metro cincuenta de dinamita pura, esta coach está enseñando a las mujeres a avanzar en sus carreras profesionales y a asumir puestos de liderazgo al reconocer su verdadero yo.",
      name: "Adela",
    }, {
      text: "La Dra. Rosa, una excelente coach de mentores, me ha ayudado a ampliar mi repertorio de estrategias de coaching. Es capaz de adoptar muchos papeles distintos, desde coach ejecutiva experta hasta hábil coach de mentores, por nombrar solo algunos. Y aportó todas esas perspectivas distintas a nuestras sesiones.",
      name: "Robert",
    }, {
      text: "Desde el primer momento, la Dra. Rosa me transmitió credibilidad mediante su calidez y su profundo conocimiento de los desafíos étnicos, políticos y de género con los que se enfrentan las mujeres en el trabajo. Tanto su empatía como los principales mensajes de coaching que me dio en una serie de momentos turbulentos tuvieron un impacto positivo.",
      name: "Darlene",
    }, {
      text: "Gracias a la diversidad de sus antecedentes, combinada con su toque personal y su perspicacia en el mundo de los negocios, la Dra. Rosa ha resultado ser la coach personal y empresarial perfecta para mí. ¡No dudaría ni un instante en volver a contratarla!",
      name: "Warren",
    }, {
      text: "La Dra. Rosa es extraordinaria como persona, como coach y como líder. Aporta estructura manteniendo a la vez la flexibilidad, calidez combinada con serenidad, diversión sin perder la concentración y empuje sin renunciar a la alegría del presente. La Dra. Rosa es una catalizadora del crecimiento. ¡Realmente marca la diferencia!",
      name: "Sheryl",
    }, {
      text: "No basta con saber escuchar. Hay que saber dar consejos constructivos y prácticos. La Dra. Rosa está a la altura en ambos frentes.",
      name: "Jerry",
    }],
    contactText: "Ponte en contacto con la Dra. Rosa para emprender hoy mismo tu camino de coaching.",
    contactFormFields: [{
      label: "Nombre",
      name: "name",
      type: "text",
    }, {
      label: "Correo electrónico",
      name: "email",
      type: "text",
    }, {
      label: "Mensaje",
      name: "message",
      type: "textarea",
    }],
    contactFormButton: [
      "Enviar",
      "Enviando...",
    ],
    formMessages: [
      "Complete todos los campos.",
      "Por favor proporcione una dirección de correo electrónico válida.",
      "Gracias! Estaremos en contacto pronto.",
    ],
  },
}
