export const colors = {
  brandPrimary: '#3B85C2',
  brandSecondary: '#68A8DF',
  lightestGray: '#F7F7F7',
  lightGray: '#F3F3F3',
  gray: '#A6A6A6',
  mediumLightGray: '#BFBFBE',
  mediumGray: '#A0A0A0',
  mediumDarkGray: '#787878',
  darkGray: '#808080',
  darkestGray: '#333435',
  white: '#FDFDFD',
}
