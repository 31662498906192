import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Button from '../components/button'
import Icon from './icon'
import { colors } from '../util/colors'
import filter from 'lodash/filter'

const buttonStyles = css`
  height: max(3rem, calc(1.4vw + 1.4vh + 1vmin));
  background-color: ${colors.white};
  font-size: max(1rem,calc(0.8vw + 0.2vh + 0.8vmin));
  div {
    padding: 0;
  }
  &:hover {
    color: ${colors.brandPrimary};
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
const OptionButton = styled(Button)`
  ${buttonStyles}
  border-top-left-radius: ${p => p.isOpen ? 0 : 0.4}rem;
  border-top-right-radius: ${p => p.isOpen ? 0 : 0.4}rem;
  padding: 0 max(1rem, 1vw);
  position: absolute;
  border-top: none;
  top: max(3rem, calc(1.4vw + 1.4vh + 1vmin));
  width: 100%;
`
const SelectedButton = styled(Button)`
  ${buttonStyles}
  border-bottom-left-radius: ${p => p.isOpen ? 0 : 0.4}rem;
  border-bottom-right-radius: ${p => p.isOpen ? 0 : 0.4}rem;
  padding: 0 max(1rem, 1vw);
`
const StyledIcon = styled(Icon)`
  transform: ${p => p.isOpen ? 'rotate(180deg)' : 'none'};
`

const Dropdown = ({
  onChange,
  options,
  selectedOption,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const filteredOptions = filter(options, option => option.value !== selectedOption.value)

  return (
    <Container>
      <SelectedButton
        outline
        color={colors.brandSecondary}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption.label}
        <StyledIcon
          isOpen={isOpen}
          name="arrowDown"
          size={24}
        />
      </SelectedButton>
      {isOpen && filteredOptions.map(option => (
        <OptionButton
          key={`option-${option.value}`}
          outline
          color={colors.brandSecondary}
          isOpen={isOpen}
          onClick={() => {
            setIsOpen(!isOpen)
            onChange(option)
          }}
        >
          {option.label}
        </OptionButton>
      ))}
    </Container>
  )
}

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  selectedOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
}

export default Dropdown
