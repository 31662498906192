import React from 'react'
import PropTypes from 'prop-types'
import stylePropType from 'react-style-proptype'
import styled from 'styled-components'
import { colors } from '../util/colors'

const ButtonWrapper = styled.button`
  background-color: ${p => p.disabled ? colors.lightGray : p.backgroundColor};
  border: ${p => p.outline ? `2px solid ${p.disabled ? colors.mediumLightGray : p.color}` : 'none'};
  border-radius: 0.4rem;
  color: ${p => p.disabled ? colors.mediumLightGray : p.fontColor};
  cursor: pointer;
  font-size: max(1rem, calc(0.9vw + 0.9vh + 0.2vmin));
  font-family: inherit;
  font-weight: 700;
  height: max(3.2rem, calc(2.2vw + 2.2vh + 2vmin));
  letter-spacing: 0.05rem;
  outline: none;
  overflow: hidden;
  padding: 0;
  pointer-events: ${p => p.disabled ? 'none' : 'all'};
`
const ButtonContents = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0 1.8rem;
  justify-content: center;
  transition: background-color 0.5s ease;
  -webkit-transition: background-color 0.5s ease;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`

const Button = ({
  accessibilityLabel,
  children,
  color,
  outline,
  style,
  textColor,
  ...remainingProps
}) => (
  <ButtonWrapper
    aria-label={accessibilityLabel}
    backgroundColor={outline ? 'transparent' : color}
    color={color}
    fontColor={outline ? color : textColor}
    outline={outline}
    style={style}
    {...remainingProps}
  >
    <ButtonContents>
      {children}
    </ButtonContents>
  </ButtonWrapper>
)

Button.propTypes = {
  accessibilityLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  style: stylePropType,
  textColor: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
}
Button.defaultProps = {
  accessibilityLabel: '',
  color: colors.brandPrimary,
  disabled: false,
  outline: false,
  style: {},
  textColor: colors.white,
  type: 'button',
}

export default Button
