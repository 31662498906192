import React from 'react'
import styled from 'styled-components'
import Icon from './icon'
import { colors } from '../util/colors'

const Container = styled.div`
  background-color: ${colors.brandPrimary};
  display: flex;
  width: 100%;
`
const Main = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 3rem 1.5rem;
  position: relative;
  width: 100%;
`
const Text = styled.p`
  color: ${colors.white};
  margin: 2rem 0;
  opacity: 0.6;
  font-weight: 600;
`
const Link = styled.a`
  color: ${colors.white};
  opacity: 0.5;
  text-decoration: none;
`

const Footer = () => (
  <Container>
    <Main>
      <Text>© {new Date().getFullYear()}, Dr. Rosa Coaching</Text>
      <Link href="https://www.linkedin.com/in/rosabelzer" target="_blank" rel="noopener noreferrer">
        <Icon name="linkedIn" size={24} />
      </Link>
    </Main>
  </Container>
)

export default Footer
