import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Dropdown from './dropdown'
import Logo from './logo'
import Icon from './icon'
import { colors } from '../util/colors'
import { INTIIAL_HEADER_VIEWHEIGHT } from '../util/constants'
import { translateOptions } from '../util/languages'

const transitionStyles = css`
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
`
const Container = styled.div`
  background:  ${colors.white};
  height: ${p => p.height}vh;
  min-height: 70px;
  width: 100%;
  position: fixed;
  z-index: 10;
  ${transitionStyles}

  @media (max-width: 650px) {
    height: ${p => p.height}vh;
    ${transitionStyles}
  }
`
const Main = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: min(2rem, 3vw);
  width: 100%;
`
const NavMenu = styled.ul`
  align-items: baseline;
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (max-width: 650px) {
    display: none;
  }
`
const NavMenuWrapper = styled.div`
  display: flex;
  align-items: center;
`
const NavItem = styled.li`
  margin-right: max(1rem, 3vw);
  &:first-child {
    margin-left: max(1rem, 3vw);
  }
`
const NavLink = styled.button`
  background: none;
  border: none;
  color: ${colors.brandSecondary};
  cursor: pointer;
  font-size: max(1.2rem, calc(0.8vw + 0.8vh + 0.5vmin));
  font-family: inherit;
  font-weight: 700;
  padding: 0;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  outline: none;

  &:hover {
    color: ${colors.brandPrimary};
  }
`
const MobileNavMenu = styled.ul`
  align-items: flex-end;
  background-color: ${colors.lightGray};
  bottom: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0 ${p => p.height / 4}vh ${p => p.height / 4}vh;
  position: fixed;
  right: ${p => p.isMenuOpen ? 0 : -12}rem;
  top: 0;
  transition: right 200ms ease-in-out, visibility 200ms ease-in-out;
  -webkit-transition: right 200ms ease-in-out, visibility 200ms ease-in-out;
  width: 12rem;
  visibility: ${p => p.isMenuOpen ? 'visible' : 'hidden'};
  z-index: 1000;
`
const MobileNavItem = styled.li`
  padding: 0.8rem 0;
`
const MobileOverlay = styled.div`
  background-color: ${colors.darkestGray};
  bottom: 0;
  left: 0;
  opacity: ${p => p.isMenuOpen ? 0.5 : 0};
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
  -webkit-transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
  visibility: ${p => p.isMenuOpen ? 'visible' : 'hidden'};
  z-index: 999;
`
const Button = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: ${p => p.height}vh;
  justify-content: center;
  margin: 0;
  outline: none;
`
const MenuButton = styled(Button)`
  @media (min-width: 651px) {
    display: none;
  }
`

const Header = ({
  height,
  handleNavigate,
  handleTranslate,
  language,
  navigationLinks,
  refs,
  siteTitle,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    return (
      <Container id="header" height={height}>
        <Main>
          <NavLink onClick={() => handleNavigate(refs['homeRef'])}>
            <Logo height={height} />
          </NavLink>
          {/*  DESKTOP NAV MENU */}
          <NavMenuWrapper>
            <NavMenu role="navigation">
              {navigationLinks.map(({ label, ref }) => (
                <NavItem key={`navItem-${label}`}>
                  <NavLink onClick={() => handleNavigate(refs[ref])}>
                    {label}
                  </NavLink>
                </NavItem>
              ))}
            </NavMenu>
            <Dropdown
              onChange={handleTranslate}
              options={translateOptions}
              selectedOption={language}
            />
          </NavMenuWrapper>
          {/*  MOBILE NAV MENU */}
          <MenuButton
            height={height}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Icon
              color={colors.brandSecondary}
              name="menu"
              size={32}
            />
          </MenuButton>
          <MobileNavMenu
            role="navigation"
            isMenuOpen={isMenuOpen}
            height={height}
          >
            <Button
              height={height}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <Icon
                color={colors.brandSecondary}
                name="close"
                size={32}
              />
            </Button>
            {navigationLinks.map(({ label, ref }) => (
              <MobileNavItem key={`navItem-${label}}`}>
                <NavLink
                  onClick={() => {
                    setIsMenuOpen(!isMenuOpen)
                    handleNavigate(refs[ref])
                  }}
                >
                  {label}
                </NavLink>
              </MobileNavItem>
            ))}
          </MobileNavMenu>
          <MobileOverlay
            isMenuOpen={isMenuOpen}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
        </Main>
      </Container>
    )
}

Header.propTypes = {
  height: PropTypes.number,
  handleNavigate: PropTypes.func,
  handleTranslate: PropTypes.func,
  language: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  navigationLinks: PropTypes.arrayOf(PropTypes.shape()),
  refs: PropTypes.shape(),
  siteTitle: PropTypes.string,
}
Header.defaultProps = {
  height: INTIIAL_HEADER_VIEWHEIGHT,
  handleNavigate: () => null,
  handleTranslate: () => null,
  language: { label: '', value: '' },
  navigationLinks: [],
  refs: {},
  siteTitle: '',
}

export default Header
