import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Footer from './footer'
import Header from './header'
import {
  INTIIAL_HEADER_VIEWHEIGHT,
  SCROLLED_HEADER_VIEWHEIGHT,
} from '../util/constants'
import './layout.css'

const isBrowser = typeof window !== `undefined`

const Layout = ({ children, ...rest }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const { site: { siteMetadata: { title }}} = data
  const [scrolled, setScrolled] = useState(false)

  useLayoutEffect(() => {
    if (!isBrowser) return;
    const handleScroll = () => {
      setScrolled(window.scrollY > window.innerHeight / 2)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <Header
        height={scrolled ? SCROLLED_HEADER_VIEWHEIGHT : INTIIAL_HEADER_VIEWHEIGHT}
        siteTitle={title}
        {...rest}
      />
        <main id="main">
          {children}
          <Footer />
        </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
